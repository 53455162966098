<template>
  <v-card v-if="!$route.meta.isHideBreadcrumb" class="mb-5">
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item exact :to="item.to" :disabled="item.disabled">
          {{ $t(item.text) }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </v-card>
</template>

<script>
export default {
  name: 'breadcrumbs',
  computed: {
    breadcrumbs() {
      return this.$store.getters.breadcrumbs
    }
  }
}
</script>
